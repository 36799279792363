/* RTFEditor.css */

.rdw-editor-toolbar {
  display: flex; /* flex */
  margin-bottom: 0; /* mb-0 */
  color: #6b7280;
  align-items: center; /* items-center */
  padding: 0.5rem 0.5rem; /* px-2 py-1 */
  background-color: #f9fafb; /* bg-gray-50 */
  border-left: 1px solid #e5e7eb; /* border-l border-gray-200 */
  border-top: 1px solid #e5e7eb; /* border-t border-gray-200 */
  border-bottom: 1px solid #e5e7eb; /* border-t border-gray-200 */
  border-right: 1px solid #e5e7eb; /* border-r border-gray-200 */
  border-top-left-radius: 0.5rem; /* rounded-t-lg */
  border-top-right-radius: 0.5rem; /* rounded-t-lg */
}

.rdw-editor-main {
  background-color: #ffffff; /* bg-white */

  padding: 1rem; /* px-4 */
  border-bottom: 1px solid #e5e7eb; /* border-b border-gray-200 */
  border-left: 1px solid #e5e7eb; /* border-l border-gray-200 */
  border-right: 1px solid #e5e7eb; /* border-r border-gray-200 */
  border-bottom-left-radius: 0.55rem; /* rounded-b-lg */
  border-bottom-right-radius: 0.5rem; /* rounded-b-lg */
}
.rdw-option-wrapper {
  background-color: #f9fafb; /* bg-gray-50 */
  color: #1f2937; /* text-gray-800 */
  border-radius: 0.375rem; /* rounded-md */
  padding: 0.25rem 0.5rem; /* px-2 py-1 */
  height: 24px; /* h-6 */
  border: none; /* border-none */
}

.rdw-editor-toolbar .rdw-option-wrapper:hover {
  background-color: #e5e7eb; /* bg-gray-100 */
  color: #111827; /* text-gray-900 */
  box-shadow: none; /* shadow-none */
}

.rdw-editor-toolbar .rdw-option-wrapper:active {
  background-color: #e5e7eb; /* bg-gray-50 */
  color: #1f2937; /* text-gray-800 */
}
.rdw-option-wrapper img {
  fill: #6b7280; /* text-gray-500 */
}
